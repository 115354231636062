import React, { useEffect } from 'react'
import { useMeeting } from '@videosdk.live/react-sdk';
import { useSelector, useDispatch } from 'react-redux';
import { setRaiseHandEnabled, setHandRaised, setHideRaiseHandButton, setHideLowerHandButton, setHideAttendeeMuteButton, setMuteOn, setStartedSpeaking, setWebcamOn, clearRaiseHandList } from "../context/data/dataSlice";

const Controls = ({
  socket,
  you,
}) => {
  const dispatch = useDispatch()
  const handRaised = useSelector(state => state.data.handRaised)
  const hideCoachMuteButton = useSelector(state => state.data.hideCoachMuteButton)
  const hideAttendeeMuteButton = useSelector(state => state.data.hideAttendeeMuteButton)
  const hideRRButton = useSelector(state => state.data.hideRRButton)
  const hideRaiseHandButton = useSelector(state => state.data.hideRaiseHandButton)
  const hideLowerHandButton = useSelector(state => state.data.hideLowerHandButton)
  const muteOn = useSelector(state => state.data.muteOn)
  const raiseHandEnabled = useSelector(state => state.data.raiseHandEnabled)
  const startedSpeaking = useSelector(state => state.data.startedSpeaking)
  const webcamOn = useSelector(state => state.data.webcamOn)
  const currentState = useSelector(state => state.data.currentState)
  const raiseHandList = useSelector(state => state.data.raiseHandList)

  const { leave, toggleMic, toggleWebcam, muteMic, unmuteMic } = useMeeting();

  function endTurn() {
    handleMuteAttendee();
    muteMic()
  }

  function leaveStream() {
    leave();
    sessionStorage.clear()
  }
  useEffect(() => {
    socket.on("coachHasEnabledHandRaise", () => {
      dispatch(setRaiseHandEnabled(true))
    })

    socket.on("coachHasRevokedHandRaise", () => {
      dispatch(setRaiseHandEnabled(false))
    })

    socket.on("endYourTurn", () => {
      dispatch(setHandRaised(false))
      endTurn()
    })
  }, [socket])

  const handleMuteCoach = () => {
    dispatch(setMuteOn(!muteOn))
  }

  const handleMuteAttendee = () => {
    dispatch(setMuteOn(true))
    dispatch(setHideAttendeeMuteButton(true))
    dispatch(setStartedSpeaking(false))
    socket.emit("endTurn", currentState)
  }

  const handleUnmuteAttendee = () => {
    dispatch(setMuteOn(false))
    dispatch(setStartedSpeaking(true))
  }

  const handleWebcam = () => {
    dispatch(setWebcamOn(!webcamOn))
  }

  const handleRoundRobin = () => {
    socket.emit("checkRoundRobin")
  }


  return (
    <div>
      {you && you.type === 'coach' &&
        <>
          {hideCoachMuteButton ? '' :
            <button
              onClick={(e) => {
                e.preventDefault()
                handleMuteCoach();
                toggleMic()
              }}
            >
              {muteOn ? "Unmute" : "Mute"}
            </button>
          }
          <button
            onClick={(e) => {
              e.preventDefault()
              handleWebcam();
              toggleWebcam()
            }}
          >
            {webcamOn ? "Turn Off Webcam" : "Turn On Webcam"}
          </button>
          {!hideRRButton &&
            <button onClick={(e) => {
              e.preventDefault();
              console.log("Cliked Start RR")
              handleRoundRobin();
              muteMic();
            }}>
              Start Round Robin
            </button>
          }
          {!hideRaiseHandButton && hideRRButton && hideLowerHandButton &&
            <button onClick={(e) => {
              e.preventDefault();
              dispatch(setHideRaiseHandButton(true));
              dispatch(setHideLowerHandButton(false))
              socket.emit("handRaiseEnabled")
            }}>
              Allow Raise Hand
            </button>
          }
          {!hideLowerHandButton && hideRRButton && hideRaiseHandButton &&
            <button onClick={(e) => {
              e.preventDefault();
              dispatch(setHideRaiseHandButton(false));
              dispatch(setHideLowerHandButton(true));
              socket.emit("handRaiseRevoked", raiseHandList)
              // setRaiseHandList([])
              dispatch(clearRaiseHandList())
            }}>
              Lower All Hands
            </button>
          }

          <a href="https://www.google.com">
            <button onClick={leaveStream}>
              Leave Stream
            </button>
          </a>
        </>
      }
      {you && you.type === 'attendee' &&
        <>
          {!hideAttendeeMuteButton &&
            <>
              {startedSpeaking ?
                <button onClick={(e) => {
                  e.preventDefault();
                  endTurn()
                }}
                >
                  Mute
                </button>
                :
                <button onClick={(e) => {
                  e.preventDefault();
                  handleUnmuteAttendee();
                  unmuteMic()
                }}
                >
                  Unmute
                </button>
              }
            </>
          }
          {raiseHandEnabled && !handRaised &&
            <button onClick={(e) => {
              e.preventDefault();
              dispatch(setHandRaised(true))
              socket.emit("attendeeRaisedTheirHand", {
                username: you.username,
                socketId: socket.id
              })
            }}
            >
              Raise Your Hand
            </button>
          }
          {raiseHandEnabled && handRaised &&
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(setHandRaised(false))
                endTurn()
                console.log("socket id is : ", socket.id)
                socket.emit("attendeeLoweredTheirHand", {
                  username: you.username,
                  socketId: socket.id
                })
              }}
            >
              Lower Your Hand
            </button>
          }
          <button onClick={(e) => {
            e.preventDefault();
            handleWebcam();
            toggleWebcam();
          }}
          >
            {webcamOn ? "Turn Off Webcam" : "Turn On Webcam"}
          </button>
          <a href="https://www.google.com">
            <button onClick={leaveStream}>
              Leave Stream
            </button>
          </a>
        </>
      }
    </div>
  );
}


export default Controls

import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactPlayer from "react-player";
import { useParticipant } from "@videosdk.live/react-sdk";
import { CameraOff, CameraOn, InfoCicle, MicOff, MicOn } from "../assets/icons/Icons";
import '../assets/styles/ParticipantView.css'

function ParticipantView(props) {
  const [participantSocketId, setParticipantSocketId] = useState('')
  const [getUsername, setGetUsername] = useState('')
  const [getRole, setGetRole] = useState('')
  const micRef = useRef(null);
  const {
    webcamStream,
    micStream,
    webcamOn,
    micOn,
    isLocal,
    displayName
  } = useParticipant(props.participantId);

  useEffect(() => {
    props.socket.on("sendingProfileInfo", (data) => {
      setGetUsername(data.username)
      setGetRole(data.type)
    })
  }, [props.socket])

  useEffect(() => {
    if (displayName) {
      setParticipantSocketId(displayName.slice(displayName.indexOf("[Id]") + 4, displayName.indexOf("[end]")))
    }
  }, [displayName])

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  function getProfileInfo() {
    if (participantSocketId !== '') {
      props.socket.emit("getProfileInfo", { id: participantSocketId })
    }
  }

  return (
    <div className="screenDiv">
      <audio
        ref={micRef}
        autoPlay
        playsInline
        muted={isLocal}
      />
      <div className="screen">
        {webcamOn ? (
          <ReactPlayer
            playsinline
            pip={false}
            light={false}
            controls={false}
            muted={true}
            playing={true}
            url={videoStream}
            height={"175px"}
            width={"300px"}
            onError={(err) => { console.log("Participant video error: ", err); }}
          />

        )
          :
          (<div className="screen"></div>)
        }
      </div>
      <div className="control">
        <div className="usernameDisplay">
          {displayName.slice(10, displayName.indexOf("[Role]"))} {props.position === 0 && "(You)"}
        </div>
        <div className="visibility">
          {webcamOn ? <CameraOn /> : <CameraOff />}
          {micOn ? <MicOn /> : <MicOff />}
          {props.you?.type === "coach" && props.position > 0 &&
            <div className="tooltip">
              <div className="tooltipText" onMouseOver={() => getProfileInfo()}>
                {getUsername && <>Username: {getUsername} <br /></>}
                {getRole && <>Role: {getRole}<br /></>}
              </div>
              <InfoCicle />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default ParticipantView
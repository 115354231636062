import React, { useEffect, useState } from "react";
import '../assets/styles/Room.css'
import { MeetingProvider } from "@videosdk.live/react-sdk";
import MeetingView from "./MeetingView";
import { useNavigate } from "react-router-dom";
import { createToast } from "../utils/Toast";
import { useDispatch, useSelector } from 'react-redux'
import { setMeetingId, setHideCoachMuteButton, setHideRaiseHandButton, setHideAttendeeMuteButton, setHideRRButton, setCurrentState, addAttendeeToRaiseHandList, removeAttendeeFromRaiseHandList } from "../context/data/dataSlice";

const Room = ({ socket }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [coach, setCoach] = useState([])
  const [attendees, setAttendees] = useState([])
  const [you, setYou] = useState()
  const meetingId = useSelector(state => state.data.meetingId)
  const [authToken, setAuthToken] = useState(null);
  // const [raiseHandList, setRaiseHandList] = useState([])
  const hideRRButton = useSelector(state => state.data.hideRRButton)
  const hideRaiseHandButton = useSelector(state => state.data.hideRaiseHandButton)
  const hideLowerHandButton = useSelector(state => state.data.hideLowerHandButton)
  const raiseHandList = useSelector(state => state.data.raiseHandList)

  useEffect(() => {
    // /room is a protected route so any unregistered user cannot join
    if (sessionStorage.getItem('username') == null) {
      navigate('/')
    }
    else {
      if (sessionStorage.getItem('alreadyVisited')) {
        sessionStorage.clear()
        navigate('/exit')
      }
      else {
        sessionStorage.setItem('alreadyVisited', true)
        sessionStorage.setItem('joined', false)
        setTimeout(() => {
          if (sessionStorage.getItem('joined').toString() === "false") {
            socket.emit("newJoinee", {
              username: sessionStorage.getItem("username"),
              type: sessionStorage.getItem("type"),
              socketID: socket.id,
              time: "secondTime"
            })
          }
        }, 5 * 1000)

        setTimeout(() => {
          if (sessionStorage.getItem('joined').toString() === "false") {
            sessionStorage.clear()
            navigate('/exit')
          }
        }, 30 * 1000)
      }
    }
  }, [])

  useEffect(() => {
    socket.on("newUserResponse", data => {
      console.log("Timestamp emitted at: ", data.timeStamp, " but received to other users at: ", Date.now())
      setCoach(data.coach)
      setAttendees(data.attendees)
    })

    socket.on('connect_error', function (err) {
      console.log("client connect_error: ", err);
    });

    socket.on('connect_timeout', function (err) {
      console.log("client connect_timeout: ", err);
    });

    socket.on("tellAllRRStarted", () => {
      dispatch(setCurrentState("RoundRobin"))
      createToast({
        type: "info",
        data: "Round Robin session started by coach!",
        autoClose: 5000,
      })
    })

    socket.on("your_Credentials", data => {
      sessionStorage.setItem('meetingId', data.meetingId)
      sessionStorage.setItem('authToken', data.authToken)
      setYou({
        type: data.type,
        username: data.username,
        id: data.id
      })
      dispatch(setMeetingId(data.meetingId))
      setAuthToken(data.authToken)
    })

    socket.on("everySixtySeconds", (data) => {
      createToast({
        type: "info",
        data: data,
      })
    })

    socket.on("coachLeftDuringRoundRobin", () => {
      createToast({
        type: "info",
        data: "The coach has left the session during round robin",
        autoClose: 5000
      })
      console.log("Coach left during rr")
      sessionStorage.clear()
      navigate('/exit')
    })

    socket.on('unmuteCoach', () => {
      dispatch(setHideCoachMuteButton(false))
      dispatch(setHideRaiseHandButton(false))
    })

    socket.on("whoseTurn", (data) => {
      createToast({
        type: "info",
        data: data,
        autoClose: 5000
      })
    })

    socket.on("unmuteInRoundRobin", (data) => {
      dispatch(setCurrentState(data.type))
      createToast({
        type: "info",
        data: data.message,
      })
      dispatch(setHideAttendeeMuteButton(false))
    })

    socket.on("addAttendeeToRaiseHandList", socketData => {
      // setRaiseHandList(oldList => [...oldList, socketData])
      dispatch(addAttendeeToRaiseHandList(socketData))
    })

    socket.on("removeAttendeeFromRaiseHandList", socketData => {
      // setRaiseHandList(currentList => currentList.filter(attendee => attendee.socketId !== socketData.socketId))

      dispatch(removeAttendeeFromRaiseHandList(socketData.socketId))
    })

  }, [socket])


  useEffect(() => {
    socket.on("roundRobinState", (data) => {
      console.log("RR state received by coach: ", data)
      if (data === "WAITING") {
        createToast({
          type: "error",
          data: "Please wait for atleast one attendee to join!",
          autoClose: 5000
        })
      }
      else if (data === "READYTOSTART") {
        socket.emit("startRoundRobin")
        dispatch(setHideCoachMuteButton(true))
        dispatch(setHideRRButton(true))
      }
    });

    return () => {
      socket.off("roundRobinState");
    };
  }, []);

  useEffect(() => {
    socket.on("endedRoundRobin", (data) => {
      console.log(data)

      createToast({
        type: "info",
        data: "End of round robin session!",
        autoClose: 5000
      })
      dispatch(setCurrentState("General"))
    })

    return () => {
      socket.off("endedRoundRobin");
    };
  }, [socket])

  return (
    <div className='Room'>
      <div className="roomBar">
        {you &&
          <>
            <h4>Username: {you.username}</h4>
            <h4>Role: {you.type}</h4>
          </>
        }
        <h2>Coach</h2>
        <ul>
          {coach?.map(coach => (
            <li>{coach.username}</li>
          ))}
        </ul>
        <h2>Attendees</h2>
        <ul>
          {attendees?.map(attendee => (
            <li>{attendee.username}</li>
          ))}
        </ul>
        {you && you.type === "coach" && !hideLowerHandButton && hideRRButton && hideRaiseHandButton &&
          <>
            <h2>Queue</h2>
            {raiseHandList.map((attendee, index) => (
              <div key={index}>
                <p>{attendee.username}</p>
                <button onClick={() => socket.emit("allowUnmute", attendee)}>
                  Allow Unmute
                </button>
                <button onClick={() => { socket.emit("muteThisAttendee", attendee) }}>
                  Mute Attendee
                </button>
              </div>
            ))}
          </>
        }
      </div>
      <div className="roomPage">
        <div className="videoScreenss">
          {you &&
            <MeetingProvider
              config={{
                meetingId,
                micEnabled: false,
                webcamEnabled: true,
                name: `[Username]${you.type === "coach" ? `Coach: ${you.username}` : you.username}[Role]${you?.type}[Id]${socket.id}[end]`,
              }}
              token={authToken}
            >
              <MeetingView
                socket={socket}
                you={you}
              />
            </MeetingProvider>
          }
        </div>
      </div>
    </div>
  )
}

export default Room

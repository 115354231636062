import { useState, useEffect } from "react"
import '../assets/styles/Home.css'
import axios from 'axios'
import { useNavigate } from "react-router-dom"
import { createToast } from "../utils/Toast";

const Home = ({ socket }) => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')

  useEffect(() => {
    if (sessionStorage.getItem('username')) {
      sessionStorage.clear()
      navigate('/exit')
    }
  }, [])

  const join = async (e, type) => {
    try {
      e.preventDefault()
      console.log("Join funciton clicked")
      // check if coach or attendee can even join or not
      const addMemberResp = await axios.post(`https://vcbackend.dilonline.in/addMember`, {
        type: type
      })

      if (addMemberResp.data.message === 'coachCanJoin' || addMemberResp.data.message === 'attendeeCanJoin') {
        // send new joinee's data to the backend
        socket.emit("newJoinee", {
          username: username,
          type: type,
          socketID: socket.id,
          time: "firstTime"
        })

        createToast({
          type: "success",
          data: "Joined very Successfully!",
          autoClose: 5000,
        })
        createToast({
          type: "info",
          data: "If you do not see the 'Join' button on the screen please wait for 5 seconds",
          autoClose: 5000,
        })
        sessionStorage.setItem('username', username)
        sessionStorage.setItem('type', type)
        setUsername('')
        navigate("/room")
      }
      // cannot join during round robin session
      else if (addMemberResp.data.message === "roundRobinStarted") {
        createToast({
          type: "error",
          data: "Cannot join meeting now as session has already started",
          autoClose: 5000,
        })
      }
      else {
        type === 'coach' ?
          createToast({
            type: "error",
            data: "Cannot join as coach because one coach is already present",
            autoClose: 5000,
          })
          :
          createToast({
            type: "error",
            data: "Cannot join as attendee because maximum capacity of 9 attendees reached",
            autoClose: 5000,
          })
      }
      setUsername('')
    } catch (error) {
      console.log("Error in join() function in Home page: ", error)
    }
  }

  return (
    <div className="Home">
      <form className="homePageForm">
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          maxLength={20}
          required
          placeholder="Enter Your Username..."
          autoComplete="off"
        />
        <button onClick={(e) => join(e, 'coach')} disabled={!username}>Join As Coach</button>
        <button onClick={(e) => join(e, 'attendee')} disabled={!username}>Join As Attendee</button>
      </form>
    </div>
  )
}

export default Home

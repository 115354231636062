import React, { useState } from 'react';
import Controls from './Controls';
import ParticipantView from './ParticipantView';
import { useMeeting } from '@videosdk.live/react-sdk';
import '../assets/styles/MeetingView.css'
import { setMeetingId } from '../context/data/dataSlice';
import { useDispatch, useSelector } from 'react-redux';

const MeetingView = ({
  socket,
  you,
}) => {
  const dispatch = useDispatch()
  const meetingId = useSelector(state => state.data.meetingId)
  const [joined, setJoined] = useState(null);
  const { join, participants } = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      dispatch(setMeetingId(null))
    },
  });

  const joinMeeting = () => {
    sessionStorage.setItem('joined', true)
    setJoined("JOINING");
    join();
  };


  return (
    <div className="container">
      <h3>Meeting Id: {meetingId}</h3>
      {joined && joined === "JOINED" ? (
        <div>
          <Controls
            socket={socket}
            you={you}
          />
          <div className="screens">
            {[...participants.keys()].map((participantId, key) => (
              <ParticipantView
                socket={socket}
                participantId={participantId}
                key={participantId}
                position={key}
                you={you}
              />
            ))}
          </div>
        </div>
      ) : joined && joined === "JOINING" ? (
        <p>Joining the meeting...</p>
      ) : (
        <button onClick={joinMeeting}>Join</button>
      )}
    </div>
  );
}

export default MeetingView

import { createSlice, current } from '@reduxjs/toolkit';

const dataSlice = createSlice({
  name: 'myFeature',
  initialState: {
    muteOn: false,
    webcamOn: true,
    hideRRButton: false,
    hideRaiseHandButton: true,
    hideLowerHandButton: true,
    raiseHandEnabled: false,
    handRaised: false,
    startedSpeaking: false,
    hideCoachMuteButton: false,
    hideAttendeeMuteButton: true,
    currentState: '',
    meetingId: null,
    raiseHandList: []
  },
  reducers: {
    setMuteOn: (state, action) => {
      state.muteOn = action.payload;
    },
    setWebcamOn: (state, action) => {
      state.webcamOn = action.payload;
    },
    setHideRRButton: (state, action) => {
      state.hideRRButton = action.payload;
    },
    setHideRaiseHandButton: (state, action) => {
      state.hideRaiseHandButton = action.payload;
    },
    setHideLowerHandButton: (state, action) => {
      state.hideLowerHandButton = action.payload;
    },
    setRaiseHandEnabled: (state, action) => {
      state.raiseHandEnabled = action.payload;
    },
    setHandRaised: (state, action) => {
      state.handRaised = action.payload;
    },
    setStartedSpeaking: (state, action) => {
      state.startedSpeaking = action.payload;
    },
    setHideCoachMuteButton: (state, action) => {
      state.hideCoachMuteButton = action.payload;
    },
    setHideAttendeeMuteButton: (state, action) => {
      state.hideAttendeeMuteButton = action.payload;
    },
    setCurrentState: (state, action) => {
      state.currentState = action.payload;
    },
    setMeetingId: (state, action) => {
      state.meetingId = action.payload;
    },
    addAttendeeToRaiseHandList: (state, action) => {
      state.raiseHandList.push(action.payload)
    },
    removeAttendeeFromRaiseHandList: (state, action) => {
      console.log("action payload print: ", action.payload)
      console.log("aaray is: ", current(state))
      state.raiseHandList = state.raiseHandList.filter(attendee => attendee.socketId !== action.payload);
    },
    clearRaiseHandList: (state, action) => {
      state.raiseHandList = []
    },
  },
});

export const {
  setMuteOn,
  setWebcamOn,
  setHideRRButton,
  setHideRaiseHandButton,
  setHideLowerHandButton,
  setRaiseHandEnabled,
  setHandRaised,
  setStartedSpeaking,
  setHideCoachMuteButton,
  setHideAttendeeMuteButton,
  setCurrentState,
  setMeetingId,
  addAttendeeToRaiseHandList,
  removeAttendeeFromRaiseHandList,
  clearRaiseHandList,
} = dataSlice.actions;

export default dataSlice.reducer;

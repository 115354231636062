import { toast } from 'react-toastify'

export function createToast({
  position = "bottom-right",
  autoClose = "false",
  type = "info",
  data = ""
}) {
  if (type === "info") {
    return toast.info(data, {
      position: position,
      autoClose: autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }
  else if (type === "success") {
    return toast.success(data, {
      position: position,
      autoClose: autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }
  else if (type === "error") {
    return toast.error(data, {
      position: position,
      autoClose: autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }
}
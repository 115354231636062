import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './components/Home'
import Room from './components/Room'
import socketIO from 'socket.io-client'
import MeetingEnded from './components/MeetingEnded'

const App = () => {
  const socket = socketIO.connect(`https://vcbackend.dilonline.in`)

  useEffect(() => {
    // Emit disconnect event with timestamp when the component is unmounted
    return () => {
      const disconnectEmitTimestamp = Date.now();
      console.log(Date.now())
      socket.emit('disconnect', disconnectEmitTimestamp);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<Home socket={socket} />} />
        <Route path='/room' element={<Room socket={socket} />} />
        <Route path='/exit' element={<MeetingEnded />} />
      </Routes>
    </>
  )
}

export default App
